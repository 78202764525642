const DayOfWeek = Object.freeze({
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 0,
    getDayOfWeek(day){
        if(day == 0) return 'Sunday'
        else if(day == 1) return 'Monday'
        else if(day == 2) return 'Tuesday'
        else if(day == 3) return 'Wednesday'
        else if(day == 4) return 'Thursday'
        else if(day == 5) return 'Friday'
        else if(day == 6) return 'Saturday'
        else throw new Error('Undefined date')
    },
    getDayNoOfWeek(day){
        if(day.toLowerCase() == 'sunday') return 0
        else if(day.toLowerCase() == 'monday') return 1
        else if(day.toLowerCase() == 'tuesday') return 2
        else if(day.toLowerCase() == 'wednesday') return 3
        else if(day.toLowerCase() == 'thursday') return 4
        else if(day.toLowerCase() == 'friday') return 5
        else if(day.toLowerCase() == 'saturday') return 6
        else throw new Error('Undefined Day')
    },
    nextDay(dayNo){
        dayNo = typeof dayNo == 'string' ? DayOfWeek.getDayNoOfWeek(dayNo) : dayNo
        dayNo = dayNo > 6 ? 0 : ++dayNo
        return DayOfWeek.getDayOfWeek(dayNo)
    },
});

const WeekDays = {
	Monday: 'Monday',
	Tuesday: 'Tuesday',
	Wednesday: 'Wednesday',
	Thursday: 'Thursday',
	Friday: 'Friday',
	Saturday: 'Saturday',
	Sunday: 'Sunday',
	WorkingDays(){
		return [this.Monday, this.Tuesday, this.Wednesday, this.Thursday, this.Friday]
	}
}

const ProjectTypes = {
	None: 'None',
	Internal: 'Internal',
	InternalCharge: 'Internal Charge',
	External: 'External'
}

const ResourcingCellColors = {
	Green: 'td-green',
	Red: 'td-red',
	Yellow: 'td-yellow',
	None: ''
}

const ResourcingRequestBlockColors = {
	Green: 'bg-success',
	Yellow: 'bg-warning',
	Red: 'bg-danger'
}

const DateFormats = {
	MMDDYYYY: 'MM/DD/yyyy',
	MMDD: 'MM/DD'
}

const PTOVesting = {
	FullAllocation: 0,
	Accrual: 1,
	Prorate: 2
}

const UserLeaveStatus = {
	System: 'System',
	Approved: 'Approved',
	Denied: 'Denied',
	Withdrawn: 'Withdrawn',
	Pending: 'Pending',
	None: 'None',
}

const UserLeaveTypes = {
	PTO: 'PTO',
	Flex: 'Flex',
	Comp: 'Comp',
	Bereavement: 'Bereavement',
	NPTO: 'NPTO'
}

const LeaveEntryType = {
	Adjustment: 'Adjustment',
	Leave: 'Leave'
}

const VolumeMetrics = {
	ToRoundedHours(minutes){
		return (Math.ceil(minutes / 6.0) / 10);
	},
	CalculateVolume(billableHours, billableHoursTarget, decimalFormat = false){
		let decimalMultiplier = decimalFormat ? 1 : 100;
		return billableHoursTarget > 0 ? (billableHours / billableHoursTarget) * decimalMultiplier : billableHours;
	},
	CalculateAverageHourlyRate(totalRate, totalMinutes){
		return (totalRate > 0 && totalMinutes > 0) ? totalRate / 100 / (totalMinutes / 60) : 0;
	},
}

export {
    DayOfWeek,
	WeekDays,
	ProjectTypes,
	ResourcingCellColors,
	ResourcingRequestBlockColors,
	DateFormats,
	PTOVesting,
	UserLeaveStatus,
	UserLeaveTypes,
	LeaveEntryType,
	VolumeMetrics
}
