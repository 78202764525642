<template>
	<div v-bind="_containerProps" class="datepicker-field">
		<label v-if="label" :class="labelClass">{{label}} <slot name="tooltip"></slot></label>
		<small v-if="description" class="form-text text-muted w-100 mt-0 mb-2">{{description}}</small>
		<div :class="fieldOuterDivClass">
			<div class="d-flex">
				<div v-if="!isInline" class="input-group-prepend"><span class="input-group-text rounded-left rounded-0"><CIcon name="cil-calendar"/></span></div>
				<v-date-picker
					v-model="selectedDate"
					v-if="!disabled"
					popover-visibility="focus"
					show-caps
					:input-props="_inputProps"
					:timezone="''"
					:mode="mode"
					:min-date="minDate"
					:is-inline="isInline"
					:popover-direction="direction"
					:disabled-dates="disabledDates"
                    :class="((clearButton && selectedDate) || quickSelect) ? 'append-buttons' : 'no-append-buttons'">
					<template #prepend-content><Icon name="cil-calendar"/></template>
				</v-date-picker>
				<input v-if="disabled" disabled="disabled" :value="formattedValue" class="form-control" />
				<div v-if="clearButton && selectedDate && !disabled" class="input-group-append">
					<span class="input-group-text" style="padding:0 9px" id="clearBtn">
                        <CButton :disabled="disabled" @click="clearDate" color="link" class="btn-sm p-0">
                            <Icon name="cil-x" />
                        </CButton>
                        <DxTooltip target="#clearBtn" show-event="mouseenter" hide-event="mouseleave" position="top">Clear</DxTooltip>
                    </span>
				</div>
                <div v-if="quickSelect" class="input-group-append">
					<span class="input-group-text" style="padding:0 9px">
                        <Popover width="263px" ref="quicker-picker-popover">
                            <template #target>
                                <CButton :disabled="disabled" id="quickPicker" color="link" class="p-0">
                                    <Icon name="filter" icon-pack="bootstrap" size="1" />
                                </CButton>
                                <DxTooltip target="#quickPicker" show-event="mouseenter" hide-event="mouseleave" position="top">Quick select</DxTooltip>
                            </template>
                            <template #content>
                                <div class="row quick-picker">
                                    <div class="col-6 mb-2">
                                        <p><a @click="today" class="text-dark pointer">Today</a></p>
                                    </div>
                                    <div class="col-6 mb-2">
                                        <p><a @click="yesterday" class="text-dark pointer">Yesterday</a></p>
                                    </div>
                                    <div class="col-6 mb-2">
                                        <h6>Year</h6>
                                        <p><a @click="selectRange('year','last')" class="text-dark pointer">Last Year</a></p>
                                        <p><a @click="selectRange('year','this')" class="text-dark pointer">This Year</a></p>
										<p><a @click="selectRange('year','now')" class="text-dark pointer">Year To Date</a></p>
                                        <p v-if="!hideFutureSelection"><a @click="selectRange('year','next')" class="text-dark pointer">Next Year</a></p>
                                    </div>
                                    <div class="col-6 mb-2">
                                        <h6>Quarter</h6>
                                        <p><a @click="selectRange('quarter','last')" class="text-dark pointer">Last Quarter</a></p>
                                        <p><a @click="selectRange('quarter','this')" class="text-dark pointer">This Quarter</a></p>
                                        <p v-if="!hideFutureSelection"><a @click="selectRange('quarter','next')" class="text-dark pointer">Next Quarter</a></p>
                                    </div>
                                    <div class="col-6">
                                        <h6>Month</h6>
                                        <p><a @click="selectRange('month','last')" class="text-dark pointer">Last Month</a></p>
                                        <p><a @click="selectRange('month','this')" class="text-dark pointer">This Month</a></p>
                                        <p v-if="!hideFutureSelection"><a @click="selectRange('month','next')" class="text-dark pointer">Next Month</a></p>
                                    </div>
                                    <div class="col-6">
                                        <h6>Week</h6>
                                        <p><a @click="selectRange('week','last')" class="text-dark pointer">Last Week</a></p>
                                        <p><a @click="selectRange('week','this')" class="text-dark pointer">This Week</a></p>
                                        <p v-if="!hideFutureSelection"><a @click="selectRange('week','next')" class="text-dark pointer">Next Week</a></p>
                                    </div>
                                </div>
                            </template>
                        </Popover>
                    </span>
				</div>
			</div>
			<div v-if="showRangeLinks" class="formfield-quick-select">
				<span class="small">Quick Select:</span>
				<button type="button" @click="selectThisYear()" class="btn btn-sm btn-light mx-1 my-2">This Year</button>
				<button type="button" @click="selectThisMonth()" class="btn btn-sm btn-light my-2">This Month</button>
			</div>
			<div v-if="!_isValid" class="invalid-feedback" style="display: block;">{{_invalidFeedback}}</div>
			<slot name="description"></slot>
		</div>
	</div>
</template>

<script>
import { setupCalendar, DatePicker } from 'v-calendar'
import 'v-calendar/lib/v-calendar.min.css'
import {TextFormatter} from '../../mixins'
import { DxTooltip } from 'devextreme-vue/tooltip';
import moment from 'moment'

moment.updateLocale('en', {
	week: {
		dow : 1, // Monday is the first day of the week.
	}
});

setupCalendar({
	firstDayOfWeek: 2 // Monday
})
export default {
	props: ['value', 'label', 'description', 'placeholder', 'minDate',
		'inputProps', 'mode', 'isInline', 'containerProps', 'direction',
		'disabledDates', 'isValid', 'invalidFeedback', 'clearButton', 'disabled',
        'showRangeLinks','quickSelect', 'hideFutureSelection'
	],
	components: {'v-date-picker': DatePicker, DxTooltip},
	mixins: [TextFormatter],
	computed: {
		_isValid(){
			return this.isValid != null ? this.isValid : true
		},
		_invalidFeedback(){
			return this.invalidFeedback || '';
		},
		labelClass(){
			let val = 'mb-0'
			if (this._containerProps.horizontal){
				val += ' col-form-label col-sm-3'
			}
			return val;
		},
		fieldOuterDivClass(){
			return (this._containerProps.horizontal && this.label) ? 'col-sm-9' : ''
		},
		selectedDate: {
			get() {
				return (this.mode != 'range' && this.value) ? new Date(this.value) : this.value
			},
			set(v) {
				this.$emit('input',v)
				this.$emit('change',v)
			}
		},
		_inputProps() {
			const props = this.inputProps || {
				class: 'form-control pl-3 mb-0',
			}
			if (!props.placeholder && this.placeholder){
				props.placeholder = this.placeholder
			}
			if (!this._isValid){
				props.class += ' is-invalid';
			}

			return props
		},
		_containerProps(){
			return this.containerProps || {
				role: "group",
				class: "form-group datepicker-field",
			}
		},
		formattedValue(){
			return this.formatDate(this.value, 'MM/DD/YYYY')
		}
	},
	methods: {
		clearDate(){
			this.selectedDate = null
		},
		selectThisYear() {
			var now = new Date();
			this.selectedDate = {
				start: new Date(now.getFullYear(), 0, 1),
				end: new Date(now.getFullYear(), 11, 31)
			}
            this.$refs['quicker-picker-popover'].close()
		},
		selectThisMonth() {
			var now = new Date();
			this.selectedDate = {
				start: new Date(now.getFullYear(), now.getMonth(), 1),
				end: new Date(now.getFullYear(), now.getMonth() + 1, 0) // using 0 as day returns last day of last month; have to add 1 to month
			}
            this.$refs['quicker-picker-popover'].close()
		},
        today(){
            let now = new Date()
            now.setHours(0,0,0,0)
            this.selectedDate = {
                start: new Date(now),
                end: new Date(now)
            }
            this.$refs['quicker-picker-popover'].close()
        },
        yesterday(){
            let now = new Date()
            now.setDate(now.getDate() - 1)
            this.selectedDate = {
                start: new Date(now),
                end: new Date(now)
            }
            this.$refs['quicker-picker-popover'].close()
        },
		selectRange(type, when) {
			const now = new Date();
			const setDate = (start, end) => {
				this.selectedDate = { start, end };
				this.$refs['quicker-picker-popover'].close();
			};

			switch (type) {
				case 'year':
					let year;
					let endDate;
					switch (when) {
						case 'last':
							year = now.getFullYear() - 1;
							break;
						case 'next':
							year = now.getFullYear() + 1;
							break;
						case 'now':
							year= now.getFullYear();
							endDate = new Date();
						default:
							year = now.getFullYear();
					}
					setDate(new Date(year, 0, 1), endDate ? endDate : new Date(year, 11, 31));
					break;

				case 'quarter':
				case 'month':
				case 'week':
					let offset;
					switch (when) {
						case 'last':
							offset = -1;
							break;
						case 'next':
							offset = 1;
							break;
						default:
							offset = 0;
					}
					const start = moment().add(offset, `${type}s`).startOf(type).toDate();
					const end = moment().add(offset, `${type}s`).endOf(type).toDate();
					setDate(start, end);
					break;

				default:
					break;
			}
		}
	}
}
</script>
<style>
.quick-picker p{
    margin-bottom:5px;
    font-size: 13px;
}
</style>
