<template>
	<Popup
        ref="leaveRequestPopup"
        :destroyOnClose="true"
        :width="'40%'"
        :native-scroll="true"
        :hide-on-outside-click="false"
        @hidden="closePopup"
        :title="`New Leave Entry for ${Current_User_Info.FullName}`">
		<form id="userLeaveForm" ref="userLeaveForm" @submit="submit($event)">
            <label class="mb-4" v-if="supervisorStatusMsg" v-html="supervisorStatusMsg"></label>
			<DxValidationGroup ref="userLeaveValidationGroup" :validation-status="validationStatus">
                <!-- <DxField label="Type" :required="true">
                    <DxSelectBox
                        v-model="userLeave.type"
                        placeholder="Select"
                        :items="['PTO', 'Flex', 'Comp', 'Bereavement', 'NPTO']"
                        @value-changed="onUpdateType">
                        <DxValidator> <DxRequiredRule message="Type is required"/> </DxValidator>
                    </DxSelectBox>
                </DxField>
                <DxField label="Start Date" :required="true">
                    <div class="d-flex">
                        <DxDateBox
                            type="datetime"
                            v-model="userLeave.startDate"
                            placeholder="MM/dd/yyyy hh:mm a"
                            display-format="MM/dd/yyyy hh:mm a"
                            invalid-date-message="The date must have the following format: MM/dd/yyyy"
                            :element-attr="{class:'w-100'}"
                            :min-date="new Date()">
                            <DxValidator>
                                <DxRequiredRule message="Start Date is required"/>
								<DxCustomRule :reevaluate="true" :validation-callback="isStartDateInPast" message="Start Date should not be in the past" />
                                <DxAsyncRule :reevaluate="true" :validation-callback="validateDates" :message="''"/>
                            </DxValidator>
                        </DxDateBox>
                        <DxButton icon="clock" :element-attr="{class:'ml-2'}" @click="setStartDate" />
                    </div>
                </DxField>
                <DxField label="End Date" :required="true">
                    <div class="d-flex">
                        <DxDateBox
                            type="datetime"
                            v-model="userLeave.endDate"
                            placeholder="MM/dd/yyyy hh:mm a"
                            display-format="MM/dd/yyyy hh:mm a"
                            invalid-date-message="The date must have the following format: MM/dd/yyyy"
                            :element-attr="{class:'w-100'}">
                            <DxValidator>
                                <DxRequiredRule message="End Date is required"/>
                                <DxCustomRule :reevaluate="true" :validation-callback="isEndDateBeforeStartDate" message="End date should be after Start Date." />
								<DxCustomRule :reevaluate="true" :validation-callback="isEndDateInPast" message="End date should not be in the past." />
								<DxAsyncRule :reevaluate="true" :validation-callback="validateDates" :message="''"/>
                            </DxValidator>
                        </DxDateBox>
                        <DxButton icon="clock" :element-attr="{class:'ml-2'}" @click="setEndDate" />
                    </div>
                </DxField>
                <DxField label="Notes" :required="true">
                    <DxTextArea placeholder="Enter Notes" height="80" v-model="userLeave.notes">
                        <DxValidator> <DxRequiredRule message="Notes are required"/> </DxValidator>
                    </DxTextArea>
                </DxField> -->
                <!-- <div>
                    <div v-if="userLeave.type">
						<div class="alert alert-warning" v-if="validationResponse.alertHTML">
							<span v-html="validationResponse.alertHTML"></span>
						</div>
						<div class="d-flex border mb-2" style="border-radius: 10px;">
							<div class="border-right position-relative" v-width="'40px'">
								<Icon name="wallet" icon-pack="bootstrap" class="vertical-center" />
							</div>
							<div class="pl-2 pt-2 pb-2">
								<p class="mb-1">Before this {{userLeave.entryType}} {{username}}'s {{userLeave.type}}'s balance is {{balanceBefore}}</p>
								<p class="mb-0">After this {{userLeave.entryType}} {{username}}'s {{userLeave.type}}'s balance will be {{balanceAfter}}</p>
							</div>
						</div>
						<div class="d-flex border mb-2" style="border-radius: 10px;" v-if="(validationResponse.dailyTargets || []).length > 0">
							<div class="border-right position-relative" v-width="'40px'">
								<Icon name="cis-target" class="vertical-center" />
							</div>
							<div class="w-100">
								<DxScrollView width="100%" height="100%" show-scrollbar="always">
									<div style="max-height: 100px;">
										<div class="border-bottom pl-2 pb-2 pt-2" v-for="(dailyTarget, i) in validationResponse.dailyTargets" :key="`daily-target-${i}`">
											<p class="mb-1">Before this {{userLeave.entryType}} {{username}}'s daily target on {{dailyTarget.date | MMslashDD}} is {{dailyTarget.billableHoursTarget}}</p>
											<p class="mb-0">After this {{userLeave.entryType}} {{username}}'s daily target on {{dailyTarget.date | MMslashDD}} will be {{dailyTarget.expectedBillableHoursTarget}}</p>
										</div>
									</div>
								</DxScrollView>
							</div>
						</div>
                    </div>
                </div> -->
				<LeaveFormFields
					v-model="userLeave"
					ActionableIn="LeaveRequest"
					@reevaluating-start="$refs.LeaveInfoAlert.reevaluatingStart()"
					@validation-response="onValidationResponse" />
				<DxField label="Notes" :required="true">
					<DxTextArea placeholder="Enter Notes" height="80" v-model="userLeave.notes">
						<DxValidator> <DxRequiredRule message="Notes are required"/> </DxValidator>
					</DxTextArea>
				</DxField>
				<LeaveInfoAlert
					ref="LeaveInfoAlert"
					:validationResponse="validationResponse"
					:userLeave="userLeave"
					:username="username"
					:leaveBank="leaveBank" />
                <DxButton class="float-right" type="default" text="Submit" :use-submit-behavior="true" />
			</DxValidationGroup>
		</form>
        <!-- <spinner v-else/> -->
	</Popup>
</template>

<script>
import { AxiosWrapper } from '../../mixins'
import DxValidationGroup from 'devextreme-vue/validation-group';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import KDGHolidays from '../../mixins/view-mixins/KDGHolidays'
import DxButton from 'devextreme-vue/button';
import { DxTextArea } from 'devextreme-vue/text-area';
import LeaveFormFields from './LeaveFormFields.vue';
import LeaveInfoAlert from './LeaveInfoAlert.vue';
function initialState (){
    return {
        userLeave: {
            status:'Pending',
            debit:0,
            credit:0,
            requireApproval:true,
            startDate:null,
            endDate:null,
            entryType:'Leave'
        },
        balanceBefore:0,
        balanceAfter:0,
        apiRequest: false,
        message: '',
        isDailyBreak: false,
        datesError: false,
        leaveBank: {},
        supervisorStatusMsg: '',
		validationResponse: null,
		validationStatus: null
    }
}
export default {
	mixins: [AxiosWrapper, KDGHolidays],
	components: {
		DxValidationGroup,
		DxValidator,
		DxRequiredRule,
		DxButton,
		DxTextArea,
		LeaveFormFields,
		LeaveInfoAlert
	},
	data() {
		return initialState();
	},
	methods: {
        async show(){
            this.apiRequest = true
            this.$refs.leaveRequestPopup.show()
            const response = await this.get(`api/users/${this.Current_User_ID}/leave-bank`)
            this.leaveBank = response.data
            await this.checkSupervisorStatus()
			this.userLeave.userId = this.Current_User_ID
            this.apiRequest = false
        },
        async checkSupervisorStatus(){
            const response = await this.get(`api/users/leaves/supervisor-leave-status`)
            let status = JSON.parse(response.data)
            if(status.supervisorNotFound){
                this.supervisorStatusMsg = 'Please submit all of the relavant information for your leave and Kyle David will review.'
                this.userLeave.requireApproval = false
            }
            else if(!status.immediateSupervisorOnLeave || !status.nextSupervisorExists){
                this.supervisorStatusMsg = `Please submit all of the relavant information for your leave and ${status.supervisor.userName} will review.`;
            }
            else if(status.immediateSupervisorOnLeave && status.nextSupervisorExists && !status.nextSupervisorOnLeave){
                this.supervisorStatusMsg = `Please submit all of the relavant information for your leave and ${status.supervisor.userName} will review <span style="color:#DA0063">as ${status.immediateSupervisor.userName} is currently on leave.</span>`;
            }
        },
		submit(e){
			e.preventDefault()
			this.apiRequest = true
			this.userLeave.userId = this.Current_User_ID
			if(this.compareDate(this.userLeave.startDate, '>=', new Date()) || this.compareDate(this.userLeave.endDate, '>=', new Date())){
				this.post(`api/users/${this.Current_User_ID}/leaves`, this.userLeave).then(() =>{
					this.$connectionHub.$emit("new-leave-added");
					window.showSuccessToast('You have requested a leave. Please wait for your supervisor to approve.');
				}).finally(() =>{
					this.apiRequest = false
					this.$refs.leaveRequestPopup.hide()
					this.closePopup()
				})
			} else {
				this.apiRequest = false
				window.showErrorToast('You cannot submit a leave for dates in the past.')
			}
		},
        resetValues(){
            this.userLeave.credit = 0
            this.userLeave.debit = 0
            this.userLeave.startDate = null
            this.userLeave.endDate = null
        },
        closePopup(){
            Object.assign(this.$data, initialState());
            this.$emit('hidden')
        },
		onValidationResponse(response){
			this.validationResponse = response
			this.$refs.LeaveInfoAlert.evaluateBalance()
		}
	},
    computed:{
        username(){
            return this.Current_User_Infor ? this.Current_User_Info.FirstName ? this.Current_User_Info.FirstName : this.Current_User_Info.FullName.split(' ')[0] : ''
        }
    },
	watch: {
		'validationStatus'(newValue) {
			// Watch the validationStatus property of the validation group
			if (newValue === 'valid') {
			// Form is valid
				console.log('Form is valid');
			} else {
			// Form is invalid
				console.log('Form is invalid');
			}
		}
	},
}
</script>
